<template>
  <a
    class="card"
    :href="`/news/detail?id=${dataSource.newsId}${
      newWindow == 1 ? '&ddtab=true' : ''
    }`"
    :target="newWindow == 1 ? '_blank' : '_self'"
  >
    <div class="cover fl">
      <img :src="dataSource.cover" alt="cover" />
      <div class="tag" v-if="dataSource.isRead">
        {{ $t("news.have_read", 1) }}
      </div>
      <!-- 已读 -->
    </div>
    <div class="content fr">
      <h5>
        <a-tag color="#0390e9" class="tag" v-if="dataSource.isTop == 1">{{
          $t("bbs.top")
        }}</a-tag>
        <!-- 置顶 -->
        <a-tag color="#17c469" class="tag" v-if="dataSource.isNew == 1">{{
          $t("news.new")
        }}</a-tag>
        <!-- 新 -->
        <a-tag color="#dd4545" class="tag" v-if="dataSource.isHot == 1">{{
          $t("news.hot")
        }}</a-tag>
        <!-- 热 -->
        {{ dataSource.title }}
      </h5>
      <div class="info">
        <div class="item fl">
          <span v-if="dataSource.folderName">{{ dataSource.folderName }}</span>
          <span v-if="dataSource.publishTime">{{
            dateFormat(dataSource.publishTime)
          }}</span>
        </div>
        <div class="item fr">
          <EyeOutlined style="color: #999; font-size: 16px" />
          <span>{{ dataSource.viewCount }}</span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
export default {
  name: "NewsCard",
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const store = useStore();
    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      dateFormat,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  display: block;
  width: 588px;
  height: 147px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  background-color: #fff;
  transition: all 0.3s ease;
  position: relative;
  padding: 12px;
  .cover {
    .mixinImgWrap(220px; 122px);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .tag {
      position: absolute;
      left: 5px;
      top: 5px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      font-size: 12px;
      line-height: 24px;
      padding: 0 5px;
      z-index: 2;
      border-radius: 2px;
    }
    &::after {
      content: "";
      background-color: rgba(255, 255, 255, 0);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease;
    }
  }
  .content {
    width: 333px;
    height: 100%;
    h5 {
      font-size: 20px;
      .mixinEllipsis(64px, 2);
      margin: 0;
      .tag {
        vertical-align: 3px;
      }
    }
    .info {
      color: #999;
      font-size: 12px;
      .mixinFlex(space-between; center);
      padding-top: 41px;
      .item {
        .mixinFlex(flex-start; center);
      }
      span:last-child {
        margin-left: 4px;
      }
      .fl span:last-child {
        margin-left: 12px;
      }
    }
  }
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.25);
    .cover {
      &::after {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
